/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Lib dependencies
import { LoadingOverlay as NativeLoadingOverlay } from '@mantine/core';

// Style
import './LoadingOverlay.scss';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

const LoadingOverlay = (props) => (
  /* ******************************** RENDERING ******************************* */

  <NativeLoadingOverlay className="loading-overlay" {...props} />
);

export default LoadingOverlay;
