/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */
// Lib dependencies
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Translations files
import translationEnJson from '../locales/en/en.json';
import translationfrJson from '../locales/fr/fr.json';

const resources = {
  en: {
    translation: translationEnJson,
  },
  fr: {
    translation: translationfrJson,
  },
};

i18n.use(initReactI18next).init({
  debug: false,
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
  resources,
});

export default i18n;
