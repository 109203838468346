/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Lib dependencies
import { MantineProvider as NativeMantineProvider } from '@mantine/core';

// Configs
import theme from '../../configs/theme.configs';

/* -------------------------------------------------------------------------- */
/*                                  Provider                                  */
/* -------------------------------------------------------------------------- */

const MantineProvider = (props) => {
  const { children } = props;

  /* ******************************** RENDERING ******************************* */

  return (
    <NativeMantineProvider withCSSVariables withGlobalStyles withNormalizeCSS theme={theme}>
      {children}
    </NativeMantineProvider>
  );
};

export default MantineProvider;
