// Lib dependencies
import { Global } from '@mantine/core';

// Configs
import fonts from '../../configs/fonts.configs';
import globalStyles from '../../configs/globalStyles.configs';

/* -------------------------------------------------------------------------- */
/*                                  Provider                                  */
/* -------------------------------------------------------------------------- */

const GlobalStylesProvider = () => {
  /* ******************************** RENDERING ******************************* */

  return <Global styles={[fonts, globalStyles]} />;
};

export default GlobalStylesProvider;
