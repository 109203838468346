const brand = {
  key: 'Plume Mobility',
  accessKey: process.env.REACT_APP_PLUME_MOBILITY_ACCESS_KEY,
  name: 'Plume Mobility',
  url: 'https://www.plume-mobility.com/',
  features: {
    withUidCheck: false,
    withReferenceInput: true,
    withIssueRequest: true,
    withTextRecognition: false,
  },
  images: {
    logo: 'https://res.cloudinary.com/dnkj85yan/image/upload/w_1000,ar_16:9,c_fill,g_auto,e_sharpen/v1667296293/Photos/Logo_Plume_Mobility_enj0jt.jpg',
    homePage: null,
    homePageCompressed: null,
    certificateExample:
      'https://res.cloudinary.com/dnkj85yan/image/upload/e_improve,w_300,h_600,c_thumb,g_auto/v1698850621/Visuel_Certificat_Final_axolxn.png',
    certificateExampleCompressed:
      'https://res.cloudinary.com/dnkj85yan/image/upload/v1698851461/certificate-request-page/brands/Plume%20Mobility/Visuel_Certificat_Final_axolxn_Banner_tall_gbf6tm.jpg',
    favicon:
      'https://res.cloudinary.com/dnkj85yan/image/upload/w_1000,c_fill,ar_1:1,g_auto,r_max,bo_5px_solid_red,b_rgb:262c35/v1667296293/Photos/Logo_Plume_Mobility_enj0jt.jpg',
    uidExample: null,
  },
};

export default brand;
