const fonts = [
  {
    '@font-face': {
      fontFamily: 'primary',
      src: `url('https://res.cloudinary.com/dnkj85yan/raw/upload/v1686583294/certificate-request-page/fonts/Helvetica_gdpcxp.ttf') format("truetype")`,
    },
  },

  {
    '@font-face': {
      fontFamily: 'secondary',
      src: `url('https://res.cloudinary.com/dnkj85yan/raw/upload/v1686583319/certificate-request-page/fonts/Helvetica_Light_Regular_i2v4wo.otf') format("truetype")`,
    },
  },
];

export default fonts;
