/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Lib dependencies
import { ModalsProvider as NativeModalsProvider } from '@mantine/modals';

// Components
import MessageModal from '../../../shared/components/organisms/MessageModal';

/* -------------------------------------------------------------------------- */
/*                                  Provider                                  */
/* -------------------------------------------------------------------------- */

const ModalsProvider = (props) => {
  const { children } = props;

  /* ******************************** RENDERING ******************************* */

  return <NativeModalsProvider modals={{ message: MessageModal }}>{children}</NativeModalsProvider>;
};

export default ModalsProvider;
