/* -------------------------------------------------------------------------- */
/*                                   Slice                                    */
/* -------------------------------------------------------------------------- */

const userSlice = (set) => ({
  user: {
    ip: null,
    countryCode: null,
    firstName: null,
  },

  loadUser: (data) => set((state) => ({ user: { ...state.user, ...data } })),
});

export default userSlice;
