/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Lib dependencies
import { create } from 'zustand';

// Slices
import entitySlice from './slices/entity.slice';
import userSlice from './slices/user.slice';

/* -------------------------------------------------------------------------- */
/*                                   Store                                    */
/* -------------------------------------------------------------------------- */

const useStore = create((...args) => ({
  ...entitySlice(...args),
  ...userSlice(...args),
}));

export default useStore;
