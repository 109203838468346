/* -------------------------------------------------------------------------- */
/*                                   Slice                                    */
/* -------------------------------------------------------------------------- */

const entitySlice = (set) => ({
  entity: {
    isLoaded: false,
    type: null,
    data: null,
  },

  loadEntity: (entity) => set({ entity }),
});

export default entitySlice;
