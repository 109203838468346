/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

import { lazy, Suspense } from 'react';
import LoadingOverlay from '../../components/organisms/LoadingOverlay';

/**
 * Lazily load the mentioned component which resides in the page directory
 * This method will be used in routes so that the files are loaded only
 * When users are on that route
 */

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

const LazyLoadComponent = (componentName) => {
  const LazyElement = lazy(() => import(`../../../features/${componentName}`));

  /* ******************************** RENDERING ******************************* */

  return (
    <Suspense fallback={<LoadingOverlay visible />}>
      <LazyElement />
    </Suspense>
  );
};

export default LazyLoadComponent;
