const colors = {
  black: {
    main: '#000000',
    bean: '#1e1e1e',
  },

  white: {
    main: '#ffffff',
    whiteSmoke: '#fcfcfc',
    gainsboro: '#f2f2f2',
    floralWhite: '#FFFDFC',
    snow: '#FBFBFB',
  },

  gray: {
    silver: '#d9d9d9',
    taupe: '#8e8383',
    lightGray: '#cccccc',
    darkGray: '#555454',
  },

  brown: {
    saddleBrown: '#bc8d63',
  },

  red: {
    crimson: '#ff0000',
  },
};

export default colors;
