/* eslint-disable no-param-reassign */
/* eslint-disable arrow-body-style */
/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Lib dependencies
import axios from 'axios';

// Configs
import environment from '../configs/environment.configs';
import brand from '../configs/brand.configs';

/* -------------------------------------------------------------------------- */
/*                                    Helper                                  */
/* -------------------------------------------------------------------------- */

const httpClient = axios.create({
  baseURL: environment.trustPlaceApi.baseUrl,
});

/**
 * Request interceptor function
 * This interceptor is responsible for modifying the request config before it is sent.
 * It adds an 'x-access-key' header to all requests except those containing '/countries' in the URL.
 * The 'x-access-key' value is obtained from the brand state in the application's store.
 */
httpClient.interceptors.request.use(
  (config) => {
    if (!config.url.includes('/countries')) {
      config.headers['x-access-key'] = brand.accessKey;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

/**
 * Response interceptor function
 * This interceptor is responsible for handling the response from the server.
 * It extracts the response data and returns it to the service.
 */
httpClient.interceptors.response.use(
  (response) => {
    return response.data;
  },

  async (error) => {
    return Promise.reject(error);
  }
);

export default httpClient;
