/* eslint-disable import/no-unresolved */
/* eslint-disable prettier/prettier */
/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Lib dependencies
import axios from 'axios';
import environment from '../../configs/environment.configs';

// configs

/* -------------------------------------------------------------------------- */
/*                                  Services                                  */
/* -------------------------------------------------------------------------- */

class IpServices {
  static get = async () => {
    const apiResponse = await axios.get(
      `${environment.ipApi.baseUrl}/check?access_key=${environment.ipApi.accessKey}&fields=country_code,ip`
    );

    return { ip: apiResponse.data.ip, countryCode: apiResponse.data.country_code };
  };
}

export default IpServices;
