/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

import httpClient from '../../api/httpClient';

/* -------------------------------------------------------------------------- */
/*                                  Services                                  */
/* -------------------------------------------------------------------------- */

class CategoryServices {
  // Scan
  static scan = async (reference) => {
    const apiResponse = await httpClient.get(`/categories/scan/${reference}`);

    // Return category data
    return apiResponse.data;
  };
}

export default CategoryServices;
